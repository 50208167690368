var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table-simple",
        {
          staticStyle: { "max-height": "65vh", "font-size": "12px" },
          attrs: { bordered: "", responsive: "", "sticky-header": "" },
        },
        [
          _c("colgroup", [
            _c("col", { staticStyle: { width: "95px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "120px" } }),
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "140px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "140px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "115px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
          ]),
          _c(
            "b-thead",
            { attrs: { "head-variant": "light" } },
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "0" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" ID ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "95px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 상태 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "195px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 참여기준 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "300px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 제약사 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "420px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 제목 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "570px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 게시기간 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "710px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 목표인원 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "815px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 회원구분 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "920px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 진료과 ")]
                  ),
                  _c("b-th", { attrs: { colspan: "3" } }, [
                    _vm._v(" 모아보기 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "7" } }, [
                    _vm._v(" 처방팝업 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "5" } }, [
                    _vm._v(" 스케줄팝업 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "3" } }, [
                    _vm._v(" 닥터인포 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 총 클릭 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 총 클릭 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 총 참여 수 "),
                  ]),
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 참여자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 새처방 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 재처방 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 참여자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 참여자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수"),
                    _c("br"),
                    _vm._v("(중복제거) "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 참여자 수 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tbody",
            [
              _vm._l(_vm.data, function (m) {
                return [
                  _vm._l(m.memberDepts, function (dept, index) {
                    return [
                      _c(
                        "b-tr",
                        {
                          key:
                            "member-" +
                            m.liveInfoId +
                            "-dept-" +
                            dept.medicalDept,
                        },
                        [
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "0" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.liveInfoId) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "95px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(m.isFinish ? "종료" : "진행중") +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "195px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(m.allowJoinTypeName) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "300px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.clientName) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "420px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.title) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "570px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          m.startDate,
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      " ~ " +
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          m.endDate,
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "710px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.targetPeopleNum) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "815px" },
                                  attrs: { rowspan: m.memberDepts.length },
                                },
                                [_vm._v(" 회원 ")]
                              )
                            : _vm._e(),
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: { left: "920px" },
                            },
                            [_vm._v(_vm._s(dept.medicalDeptName))]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalMoaClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupMoaClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(dept.submitMoaCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.prescriptionNewCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.prescriptionReCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalPrescriptionOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupPrescriptionOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalPrescriptionClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupPrescriptionClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.submitPrescriptionCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalScheduleOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupScheduleOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalScheduleClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupScheduleClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.submitScheduleCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalDrInfoClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupDrInfoClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.submitDrInfoCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(dept.totalClickCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(dept.groupClickCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalSubmitCount
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }),
                  m.memberDepts.length > 0
                    ? _c(
                        "b-tr",
                        { key: "member-total-" + m.liveInfoId },
                        [
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: {
                                "text-align": "center",
                                left: "815px",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v(" 소계 ")]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalMoaClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupMoaClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.submitMoaCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.prescriptionNewCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.prescriptionReCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalPrescriptionOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupPrescriptionOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalPrescriptionClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupPrescriptionClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.submitPrescriptionCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalScheduleOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupScheduleOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalScheduleClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupScheduleClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.submitScheduleCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalDrInfoClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupDrInfoClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.submitDrInfoCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.groupClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.memberDepts.reduce(function (f, l) {
                                    return f + l.totalSubmitCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(m.nonMemberDepts, function (dept, index) {
                    return [
                      _c(
                        "b-tr",
                        {
                          key:
                            "nonmember-" +
                            m.liveInfoId +
                            "-dept-" +
                            dept.medicalDept,
                        },
                        [
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "0" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.liveInfoId) + " ")]
                              )
                            : _vm._e(),
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "95px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(m.isFinish ? "종료" : "진행중") +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "195px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(m.allowJoinTypeName) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "300px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.clientName) + " ")]
                              )
                            : _vm._e(),
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "420px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.title) + " ")]
                              )
                            : _vm._e(),
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "570px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          m.startDate,
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      " ~ " +
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          m.endDate,
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0 && m.memberDepts.length === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "710px" },
                                  attrs: {
                                    rowspan:
                                      (m.memberDepts.length > 0
                                        ? m.memberDepts.length + 1
                                        : 0) +
                                      (m.nonMemberDepts.length > 0
                                        ? m.nonMemberDepts.length + 1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m.targetPeopleNum) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "815px" },
                                  attrs: { rowspan: m.nonMemberDepts.length },
                                },
                                [_vm._v(" 비회원 ")]
                              )
                            : _vm._e(),
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: { left: "920px" },
                            },
                            [_vm._v(_vm._s(dept.medicalDeptName))]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalMoaClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupMoaClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(dept.submitMoaCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.prescriptionNewCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.prescriptionReCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalPrescriptionOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupPrescriptionOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalPrescriptionClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupPrescriptionClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.submitPrescriptionCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalScheduleOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupScheduleOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalScheduleClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupScheduleClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.submitScheduleCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalDrInfoClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.groupDrInfoClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.submitDrInfoCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(dept.totalClickCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(dept.groupClickCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  dept.totalSubmitCount
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }),
                  m.nonMemberDepts.length > 0
                    ? _c(
                        "b-tr",
                        { key: "nonmember-total-" + m.liveInfoId },
                        [
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: {
                                "text-align": "center",
                                left: "815px",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v(" 소계 ")]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalMoaClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupMoaClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.submitMoaCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.prescriptionNewCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.prescriptionReCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalPrescriptionOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupPrescriptionOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalPrescriptionClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupPrescriptionClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.submitPrescriptionCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalScheduleOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupScheduleOpenCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalScheduleClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupScheduleClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.submitScheduleCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalDrInfoClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupDrInfoClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.submitDrInfoCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.groupClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.nonMemberDepts.reduce(function (f, l) {
                                    return f + l.totalSubmitCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-tr",
                    { key: "all-total-" + m.liveInfoId },
                    [
                      _c(
                        "b-td",
                        {
                          staticClass: "sticky-row",
                          staticStyle: {
                            "text-align": "center",
                            left: "815px",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" 합계 ")]
                      ),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalMoaClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalMoaClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupMoaClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupMoaClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.submitMoaCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.submitMoaCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.prescriptionNewCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.prescriptionNewCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.prescriptionReCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.prescriptionReCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalPrescriptionOpenCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalPrescriptionOpenCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupPrescriptionOpenCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupPrescriptionOpenCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalPrescriptionClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalPrescriptionClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupPrescriptionClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupPrescriptionClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.submitPrescriptionCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.submitPrescriptionCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalScheduleOpenCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalScheduleOpenCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupScheduleOpenCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupScheduleOpenCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalScheduleClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalScheduleClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupScheduleClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupScheduleClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.submitScheduleCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.submitScheduleCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalDrInfoClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalDrInfoClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupDrInfoClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupDrInfoClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.submitDrInfoCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.submitDrInfoCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.groupClickCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.groupClickCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.memberDepts.reduce(function (f, l) {
                                return f + l.totalSubmitCount
                              }, 0) +
                                m.nonMemberDepts.reduce(function (f, l) {
                                  return f + l.totalSubmitCount
                                }, 0)
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }