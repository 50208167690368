var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col", { staticStyle: { width: "10%" } }),
        _c("col", { staticStyle: { width: "15%" } }),
        _c("col", { staticStyle: { width: "10%" } }),
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col", { staticStyle: { width: "10%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c("b-th", [_vm._v("ID")]),
              _c("b-th", [_vm._v("상태")]),
              _c("b-th", [_vm._v("참여기준")]),
              _c("b-th", [_vm._v("제약사")]),
              _c("b-th", [_vm._v("제목")]),
              _c("b-th", [_vm._v("게시기간")]),
              _c("b-th", [_vm._v("목표인원")]),
              _c("b-th", [_vm._v("진료과")]),
              _c("b-th", [_vm._v("총 클릭 수")]),
              _c("b-th", [
                _vm._v("총 클릭 수"),
                _c("br"),
                _vm._v("(중복제거)"),
              ]),
              _c("b-th", [_vm._v("총 참여자 수")]),
              _c("b-th", [_vm._v("결과")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.data.length > 0
        ? _c(
            "b-tbody",
            [
              _vm._l(_vm.data, function (m) {
                return [
                  _vm._l(m.departments, function (dept, deptIdx) {
                    return _c(
                      "b-tr",
                      {
                        key:
                          "survey-" +
                          m.survey.liveInfoId +
                          "-dept-" +
                          dept.medicalDepartment,
                      },
                      [
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [_vm._v(" " + _vm._s(m.survey.liveInfoId) + " ")]
                            )
                          : _vm._e(),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      m.survey.isFinish ? "종료" : "진행중"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [
                                _vm._v(
                                  " " + _vm._s(m.survey.allowJoinTypeName) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [_vm._v(" " + _vm._s(m.survey.clientName) + " ")]
                            )
                          : _vm._e(),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [_vm._v(" " + _vm._s(m.survey.title) + " ")]
                            )
                          : _vm._e(),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      m.survey.startDate,
                                      "YYYY-MM-DD"
                                    )
                                  ) +
                                    " ~ " +
                                    _vm._s(
                                      _vm._f("$dateFormatter")(
                                        m.survey.endDate,
                                        "YYYY-MM-DD"
                                      )
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [_vm._v(_vm._s(m.survey.targetPeopleNum))]
                            )
                          : _vm._e(),
                        _c("b-td", [
                          _vm._v(_vm._s(dept.medicalDepartmentName)),
                        ]),
                        _c("b-td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("$numberFormatter")(dept.totalClickCount)
                            )
                          ),
                        ]),
                        _c("b-td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("$numberFormatter")(dept.groupClickCount)
                            )
                          ),
                        ]),
                        _c("b-td", [
                          _vm._v(
                            _vm._s(_vm._f("$numberFormatter")(dept.submitCount))
                          ),
                        ]),
                        deptIdx === 0
                          ? _c(
                              "b-td",
                              { attrs: { rowspan: m.departments.length + 1 } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "underline",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.download(m.survey.liveInfoId)
                                      },
                                    },
                                  },
                                  [_vm._v("다운로드")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  m.departments.length > 0
                    ? _c(
                        "b-tr",
                        { key: "dept-total-" + m.survey.liveInfoId },
                        [
                          _c("b-td", [_vm._v(" 소계 ")]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.departments.reduce(function (f, l) {
                                    return f + l.totalClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.departments.reduce(function (f, l) {
                                    return f + l.groupClickCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  m.departments.reduce(function (f, l) {
                                    return f + l.submitCount
                                  }, 0)
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c(
            "b-tbody",
            [
              _c(
                "b-tr",
                [
                  _c("b-td", { attrs: { colspan: "12" } }, [
                    _c(
                      "div",
                      { attrs: { role: "alert", "aria-live": "polite" } },
                      [
                        _c("div", { staticClass: "text-center my-2" }, [
                          _vm._v(" 일치하는 데이터가 없습니다. "),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }