<template>
  <b-table-simple
    bordered
    responsive
    sticky-header
    style="max-height: 65vh;"
  >
    <colgroup>
      <col style="width: 7%">
      <col style="width: 7%">
      <col style="width: 7%">
      <col style="width: 10%">
      <col style="width: 15%">
      <col style="width: 10%">
      <col style="width: 7%">
      <col style="width: 10%">
      <col style="width: 8%">
      <col style="width: 8%">
      <col style="width: 8%">
      <col style="width: 8%">
    </colgroup>
    <b-thead head-variant="light">
      <b-tr>
        <b-th>ID</b-th>
        <b-th>상태</b-th>
        <b-th>참여기준</b-th>
        <b-th>제약사</b-th>
        <b-th>제목</b-th>
        <b-th>게시기간</b-th>
        <b-th>목표인원</b-th>
        <b-th>진료과</b-th>
        <b-th>총 클릭 수</b-th>
        <b-th>총 클릭 수<br>(중복제거)</b-th>
        <b-th>총 참여자 수</b-th>
        <b-th>결과</b-th>
      </b-tr>
    </b-thead>
    <b-tbody v-if="data.length > 0">
      <template v-for="m in data">
        <b-tr
          v-for="(dept, deptIdx) in m.departments"
          :key="`survey-${m.survey.liveInfoId}-dept-${dept.medicalDepartment}`"
        >
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >
            {{ m.survey.liveInfoId }}
          </b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >
            {{ (m.survey.isFinish ? "종료" : "진행중") }}
          </b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >
            {{ m.survey.allowJoinTypeName }}
          </b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >
            {{ m.survey.clientName }}
          </b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >
            {{ m.survey.title }}
          </b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >{{ m.survey.startDate | $dateFormatter('YYYY-MM-DD') }} ~ {{ m.survey.endDate | $dateFormatter('YYYY-MM-DD') }}</b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >{{ m.survey.targetPeopleNum }}</b-td>
          <b-td>{{ dept.medicalDepartmentName }}</b-td>
          <b-td>{{ dept.totalClickCount | $numberFormatter }}</b-td>
          <b-td>{{ dept.groupClickCount | $numberFormatter }}</b-td>
          <b-td>{{ dept.submitCount | $numberFormatter }}</b-td>
          <b-td
            v-if="deptIdx === 0"
            :rowspan="(m.departments.length + 1)"
          >
            <a
              href="#"
              class="underline"
              @click.prevent="download(m.survey.liveInfoId)"
            >다운로드</a>
          </b-td>
        </b-tr>
        <b-tr
          v-if="m.departments.length > 0"
          :key="`dept-total-${m.survey.liveInfoId}`"
        >
          <b-td>
            소계
          </b-td>
          <b-td>{{ m.departments.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>
          <b-td>{{ m.departments.reduce((f, l) => f + l.groupClickCount, 0) | $numberFormatter }}</b-td>
          <b-td>{{ m.departments.reduce((f, l) => f + l.submitCount, 0) | $numberFormatter }}</b-td>
        </b-tr>
      </template>
    </b-tbody>

    <b-tbody v-else>
      <b-tr>
        <b-td colspan="12">
          <div
            role="alert"
            aria-live="polite"
          >
            <div class="text-center my-2">
              일치하는 데이터가 없습니다.
            </div>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import { saveAs } from "file-saver"
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  setup() {
    onMounted(() => {
    })

    const download = liveInfoId => {
      axios.get(`/fa/statistics/survey/download/${liveInfoId}`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])
          const fileName = `설문결과_${dayjs().format('YYYYMMDDHHmmss')}`

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    return {
      download,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}
</style>
