<template>
  <div>
    <b-table-simple
      bordered
      responsive
      sticky-header
      style="max-height: 65vh; font-size: 12px"
    >
      <colgroup>
        <!-- ID -->
        <col style="width: 95px">
        <!-- 상태 -->
        <col style="width: 100px">
        <!-- 참여기준 -->
        <col style="width: 105px">
        <!-- 구분 -->
        <col style="width: 120px">
        <!-- 제목 -->
        <col style="width: 150px">
        <!-- 게시기간 -->
        <col style="width: 140px">
        <!-- 목표인원 -->
        <col style="width: 105px">
        <!-- 회원구분 -->
        <col style="width: 105px">
        <!-- 진료과 -->
        <col style="width: 140px">
        <!-- 모아보기 -->
        <col style="width: 100px">
        <col style="width: 115px">
        <col style="width: 105px">
        <!-- 처방팝업 -->
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 115px">
        <col style="width: 100px">
        <col style="width: 115px">
        <col style="width: 105px">
        <!-- 스케줄팝업 -->
        <col style="width: 100px">
        <col style="width: 115px">
        <col style="width: 100px">
        <col style="width: 115px">
        <col style="width: 105px">
        <!-- 닥터인포 -->
        <col style="width: 100px">
        <col style="width: 115px">
        <col style="width: 105px">
        <!-- 소계/총계 -->
        <col style="width: 110px">
        <col style="width: 115px">
        <col style="width: 110px">
      </colgroup>
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 0"
          >
            ID
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 95px;"
          >
            상태
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 195px;"
          >
            참여기준
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 300px;"
          >
            제약사
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 420px;"
          >
            제목
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 570px;"
          >
            게시기간
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 710px;"
          >
            목표인원
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 815px;"
          >
            회원구분
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 920px;"
          >
            진료과
          </b-th>
          <b-th colspan="3">
            모아보기
          </b-th>
          <b-th colspan="7">
            처방팝업
          </b-th>
          <b-th colspan="5">
            스케줄팝업
          </b-th>
          <b-th colspan="3">
            닥터인포
          </b-th>
          <!-- 소계/총계 -->
          <b-th rowspan="2">
            총 클릭 수
          </b-th>
          <b-th rowspan="2">
            총 클릭 수<br>(중복제거)
          </b-th>
          <b-th rowspan="2">
            총 참여 수
          </b-th>
        </b-tr>
        <b-tr>
          <!-- 모아보기 -->
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수<br>(중복제거)
          </b-th>
          <b-th style="top: 38.55px;">
            참여자 수
          </b-th>
          <!-- 처방팝업 -->
          <b-th style="top: 38.55px;">
            새처방
          </b-th>
          <b-th style="top: 38.55px;">
            재처방
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수<br>(중복제거)
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수<br>(중복제거)
          </b-th>
          <b-th style="top: 38.55px;">
            참여자 수
          </b-th>
          <!-- 스케줄팝업 -->
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수<br>(중복제거)
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수<br>(중복제거)
          </b-th>
          <b-th style="top: 38.55px;">
            참여자 수
          </b-th>
          <!-- 닥터인포 -->
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수<br>(중복제거)
          </b-th>
          <b-th style="top: 38.55px;">
            참여자 수
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="m in data">
          <template v-for="(dept, index) in m.memberDepts">
            <b-tr
              :key="`member-${m.liveInfoId}-dept-${dept.medicalDept}`"
            >
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 0"
              >
                {{ m.liveInfoId }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 95px;"
              >
                {{ (m.isFinish ? "종료" : "진행중") }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 195px;"
              >
                {{ m.allowJoinTypeName }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 300px;"
              >
                {{ m.clientName }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 420px;"
              >
                {{ m.title }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 570px;"
              >
                {{ m.startDate | $dateFormatter('YYYY-MM-DD') }} ~ {{ m.endDate | $dateFormatter('YYYY-MM-DD') }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 710px;"
              >
                {{ m.targetPeopleNum }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="m.memberDepts.length"
                class="sticky-row"
                style="left: 815px;"
              >
                회원
              </b-td>
              <b-td
                class="sticky-row"
                style="left: 920px;"
              >{{ dept.medicalDeptName }}</b-td>
              <b-td>{{ dept.totalMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitMoaCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.prescriptionNewCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.prescriptionReCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitPrescriptionCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitScheduleCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitDrInfoCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalSubmitCount | $numberFormatter }}</b-td>
            </b-tr>
          </template>
          <b-tr
            v-if="m.memberDepts.length > 0"
            :key="`member-total-${m.liveInfoId}`"
          >
            <b-td
              colspan="2"
              class="sticky-row"
              style="text-align: center; left: 815px;"
            >
              소계
            </b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.submitMoaCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.prescriptionReCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.submitScheduleCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.groupClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.memberDepts.reduce((f, l) => f + l.totalSubmitCount, 0) | $numberFormatter }}</b-td>
          </b-tr>
          <template v-for="(dept, index) in m.nonMemberDepts">
            <b-tr
              :key="`nonmember-${m.liveInfoId}-dept-${dept.medicalDept}`"
            >
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 0"
              >
                {{ m.liveInfoId }}
              </b-td>
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 95px;"
              >
                {{ (m.isFinish ? "종료" : "진행중") }}
              </b-td>
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 195px;"
              >
                {{ m.allowJoinTypeName }}
              </b-td>
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 300px;"
              >
                {{ m.clientName }}
              </b-td>
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 420px;"
              >
                {{ m.title }}
              </b-td>
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 570px;"
              >
                {{ m.startDate | $dateFormatter('YYYY-MM-DD') }} ~ {{ m.endDate | $dateFormatter('YYYY-MM-DD') }}
              </b-td>
              <b-td
                v-if="index === 0 && m.memberDepts.length === 0"
                :rowspan="(m.memberDepts.length > 0 ? m.memberDepts.length + 1 : 0) + (m.nonMemberDepts.length > 0 ? m.nonMemberDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 710px;"
              >
                {{ m.targetPeopleNum }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="m.nonMemberDepts.length"
                class="sticky-row"
                style="left: 815px;"
              >
                비회원
              </b-td>
              <b-td
                class="sticky-row"
                style="left: 920px;"
              >{{ dept.medicalDeptName }}</b-td>
              <b-td>{{ dept.totalMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitMoaCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.prescriptionNewCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.prescriptionReCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitPrescriptionCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitScheduleCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.submitDrInfoCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.groupClickCount | $numberFormatter }}</b-td>
              <b-td>{{ dept.totalSubmitCount | $numberFormatter }}</b-td>
            </b-tr>
          </template>
          <b-tr
            v-if="m.nonMemberDepts.length > 0"
            :key="`nonmember-total-${m.liveInfoId}`"
          >
            <b-td
              colspan="2"
              class="sticky-row"
              style="text-align: center; left: 815px;"
            >
              소계
            </b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.submitMoaCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.prescriptionReCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.submitScheduleCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.groupClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ m.nonMemberDepts.reduce((f, l) => f + l.totalSubmitCount, 0) | $numberFormatter }}</b-td>
          </b-tr>
          <b-tr
            :key="`all-total-${m.liveInfoId}`"
          >
            <b-td
              colspan="2"
              class="sticky-row"
              style="text-align: center; left: 815px;"
            >
              합계
            </b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.submitMoaCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.submitMoaCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.prescriptionReCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.prescriptionReCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.submitScheduleCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.submitScheduleCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.groupClickCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.groupClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (m.memberDepts.reduce((f, l) => f + l.totalSubmitCount, 0) + m.nonMemberDepts.reduce((f, l) => f + l.totalSubmitCount, 0)) | $numberFormatter }}</b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
